body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;

}
h1 {
  text-align: center;
  color: #333;
}

/* search styling  */
.search-input {
  width: 400px;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid transparent;
  font-size: 18px;
  margin-bottom: 15px;
  outline: none;
  box-shadow: 0 0 15px rgba(0, 128, 255, 0.4);
  background-color: #fff;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #00aaff;
  box-shadow: 0 0 20px rgba(0, 128, 255, 0.7);
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 2px solid #00aaff;
  border-radius: 8px;
  max-width: 400px;
  box-shadow: 0 0 10px rgba(0, 128, 255, 0.3);
  background-color: #f9f9f9;
  overflow: hidden;
}

.suggestion-item {
  padding: 12px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #00aaff;
  color: #fff;
}

/* service providers list styling */
#business-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.business-item {
  background-color: #f4f8fb;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.business-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.business-photo {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
}

.business-name {
  color: #1b76d1;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.business-content p {
  display: flex;
  align-items: center;
  font-size: 1em;
  margin-bottom: 8px;
  color: #333;
}

.business-content i {
  margin-right: 8px;
  color: #1b76d1;
}

#business-list p {
  text-align: left;
}

/* service provider details styling */
.business-details {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.business-details h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.business-details img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.business-details p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 15px;
}

.business-details .address,
.business-details .phone,
.business-details .rating {
  font-weight: bold;
}

#map {
  width: 100%;
  height: 400px;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reviews-section {
  margin-top: 40px;
}

.reviews-section h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.reviews-section ul {
  list-style-type: none;
  padding: 0;
}

.reviews-section li {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reviews-section li p {
  font-size: 1rem;
  color: #555;
}

.reviews-section li .rating {
  color: #ffcc00;
  font-weight: bold;
  margin-top: 10px;
}

.no-reviews {
  font-size: 1.2rem;
  color: #999;
  margin-top: 20px;
}

/* Style for Business Details icons and text */
.details-icons {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #555;
}

.details-icons i {
  margin-right: 10px;
  color: #007bff;
}

.rating i {
  color: #ffcc00;
  margin-right: 5px;
}
